import React from "react";
import {inject, observer} from "mobx-react";
import {Button, Col, FormControl, InputGroup, Row} from "react-bootstrap";
import moment from 'moment';

const Search = inject("trackerUiService")(observer(({trackerUiService}) => {

    const renderSearchResult = (result, key) => {
        return (
            <div key={key} className="result-row" onClick={() => trackerUiService.setSelectedDate(new Date(moment(result.getDate()).locale('de').format('YYYY-MM-DD')))}>
                <Row>
                    <Col xs={1}><i className="fas fa-caret-right"/></Col>
                    <Col xs={10}>{moment(result.getDate()).locale('de').format('dd, DD.MM.YYYY')}</Col>
                </Row>
                <Row>
                    <Col xs={1}> </Col>
                    <Col xs={10}>{result.getKeywords()}</Col>
                </Row>
            </div>
        );
    };

    return (
        <React.Fragment>
            <InputGroup>
                <FormControl
                    type="input"
                    placeholder="Suche"
                    aria-label="search"
                    onChange={(e: any) => trackerUiService.updateKeywordSearchTerm(e.target.value)}
                    onKeyDown={(e) => {if(e.keyCode === 13){trackerUiService.searchKeywords()}}}
                    value={trackerUiService.getKeywordSearchTerm()}
                />
                <InputGroup.Append>
                    <Button variant="primary"
                            onClick={() => trackerUiService.searchKeywords()}>
                        <i className="fas fa-search-location"/>
                    </Button>
                </InputGroup.Append>
            </InputGroup>

            <div className="result-box">
                {trackerUiService.getKeywordsSearchResult().map((result, key) => {
                    return renderSearchResult(result, key);
                })}
            </div>
        </React.Fragment>
    )
}));

export default Search;