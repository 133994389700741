export default class MovedMarkerCommand {
    id: number;
    lat: string;
    lon: string;

    constructor(id: number, lat: string, lon: string) {
        this.id = id;
        this.lat = lat;
        this.lon = lon;
    }
}