import {inject, observer} from "mobx-react";
import React from "react";

const EditOverlay = inject("trackerUiService")(observer(({trackerUiService}) => {

    if(trackerUiService.getSelectedPoint()){
        return (
            <div className="overlay">
                <div className="content">
                    <div>Lat:</div>
                    <div>{trackerUiService.getSelectedPoint().getLat()}</div>
                    <div>Lon:</div>
                    <div>{trackerUiService.getSelectedPoint().getLon()}</div>
                    <div>Zeit:</div>
                    <div>{trackerUiService.getSelectedPoint().getDatetime()}</div>
                    <div>Ort:</div>
                    <div>
                        {trackerUiService.getSelectedPoint().getRoute()} {trackerUiService.getSelectedPoint().getStreetNumber()}<br/>
                        {trackerUiService.getSelectedPoint().getPostalCode()} {trackerUiService.getSelectedPoint().getLocality()}
                    </div>

                    {/*<button className="btn btn-sm btn-success"><i className="fas fa-check"></i></button>*/}
                    <button className="btn btn-sm btn-edit-close" onClick={() => trackerUiService.resetTrackpointToEdit()}><i className="fas fa-times"></i></button>

                </div>
            </div>
        )
    }

    return null;



}));

export default EditOverlay;