import TrackpointDto from "../../api/tracker/TrackpointDto";

class Trackpoint {
    private id: string;
    private lat: string;
    private lon: string;
    private hdop: string;
    private altitude: string;
    private speed: string;
    private source: string;
    private address: string;
    private street_number: string;
    private route: string;
    private locality: string;new
    private sublocality_level_1: string;
    private postal_code: string;
    private fixed_position: string;
    private datetime: string;

    constructor(
        id: string,
        lat: string,
        lon: string,
        hdop: string,
        altitude: string,
        speed: string,
        source: string,
        address: string,
        street_number: string,
        route: string,
        locality: string,
        sublocality_level_1: string,
        postal_code: string,
        fixed_position: string,
        datetime: string
    ) {
        this.id = id;
        this.lat = lat;
        this.lon = lon;
        this.hdop = hdop;
        this.altitude = altitude;
        this.speed = speed;
        this.source = source;
        this.address = address;
        this.street_number = street_number;
        this.route = route;
        this.locality = locality;
        this.sublocality_level_1 = sublocality_level_1;
        this.postal_code = postal_code;
        this.fixed_position = fixed_position;
        this.datetime = datetime;
    }

    getId(): string {
        return this.id;
    }

    getLat(): string {
        return this.lat;
    }

    getLon(): string {
        return this.lon;
    }

    getHdop(): string {
        return this.hdop;
    }

    getAltitude(): string {
        return this.altitude;
    }

    getSpeed(): string {
        return this.speed;
    }

    getSource(): string {
        return this.source;
    }

    getAddress(): string {
        return this.address;
    }

    getStreetNumber(): string {
        return this.street_number;
    }

    getRoute(): string {
        return this.route;
    }

    getLocality(): string {
        return this.locality;
    }

    getSublocalityLevel1(): string {
        return this.sublocality_level_1;
    }

    getPostalCode(): string {
        return this.postal_code;
    }

    getFixedPosition(): string {
        return this.fixed_position;
    }

    getDatetime(): string {
        return this.datetime;
    }

    static createFrom(dto: TrackpointDto): Trackpoint {
        return new Trackpoint(
            dto.id,
            dto.lat,
            dto.lon,
            dto.hdop,
            dto.altitude,
            dto.speed,
            dto.source,
            dto.address,
            dto.street_number,
            dto.route,
            dto.locality,
            dto.sublocality_level_1,
            dto.postal_code,
            dto.fixed_position,
            dto.datetime)
    }

    static fromUpdated(updatedTrackpoint: Trackpoint): Trackpoint {
        return new Trackpoint(
            updatedTrackpoint.id,
            updatedTrackpoint.lat,
            updatedTrackpoint.lon,
            updatedTrackpoint.hdop,
            updatedTrackpoint.altitude,
            updatedTrackpoint.speed,
            updatedTrackpoint.source,
            updatedTrackpoint.address,
            updatedTrackpoint.street_number,
            updatedTrackpoint.route,
            updatedTrackpoint.locality,
            updatedTrackpoint.sublocality_level_1,
            updatedTrackpoint.postal_code,
            updatedTrackpoint.fixed_position,
            updatedTrackpoint.datetime)
    }
}

export default Trackpoint;