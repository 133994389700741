import NotificationUiService from "../notification/NotificationUiService";
import TrackerStore from "../../store/tracker/TrackerStore";
import TrackpointCollection from "../../model/trackpoint/TrackpointCollection";
import Keywords from "../../model/keyword/Keywords";
import KeywordsStore from "../../store/keywords/KeywordsStore";
import Trackpoint from "../../model/trackpoint/Trackpoint";

class TrackerUiService {
    private trackerStore: TrackerStore;
    private keywordStore: KeywordsStore;
    private notificationUiService: NotificationUiService;

    constructor(trackerStore: TrackerStore, keywordStore: KeywordsStore) {
        this.trackerStore = trackerStore;
        this.keywordStore = keywordStore;
        this.notificationUiService = NotificationUiService.getInstance();
    }

    getSelectedDate(): Date {
        return this.trackerStore.getSelectedDate();
    }

    setSelectedDate(date: Date): void {
        this.trackerStore.setSelectedDate(date);
        this.keywordStore.fetchKeywords(date);
    }

    oneDayBackward(): void {
        let selectedDate = this.trackerStore.getSelectedDate();
        let selectedDateCopy = new Date(selectedDate);
        selectedDateCopy.setDate(selectedDate.getDate() - 1);
        this.setSelectedDate(selectedDateCopy);
    }

    oneDayForward(): void {
        let selectedDate = this.trackerStore.getSelectedDate();
        let selectedDateCopy = new Date(selectedDate);
        selectedDateCopy.setDate(selectedDate.getDate() + 1);
        this.setSelectedDate(selectedDateCopy);
    }

    fetchTrackpointsForDate(date: Date): void {
        this.trackerStore.fetchTrackpoints(date).catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Punkte konnten nicht geladen werden!");
        });
    }

    calculateBounds(){
        if(!this.getTrackpoints() || !this.getTrackpoints().getGpxPoints()){
            return [{lat: 48.923, lng: 8.651}, {lat: 48.903, lng: 8.684}];
        }

        const polylineCoords = this.getTrackpoints().getGpxPoints();
        let latHigh = "48.923";
        let latLow = "48.903";
        let lonHigh = "8.684";
        let lonLow = "8.651";
        polylineCoords.map(point => {
            if(parseFloat(point.getLat()) > parseFloat(latHigh)){
                latHigh = point.getLat();
            }
            if(parseFloat(point.getLat()) < parseFloat(latLow)){
                latLow = point.getLat();
            }
            if(parseFloat(point.getLon()) > parseFloat(lonHigh)){
                lonHigh = point.getLon();
            }
            if(parseFloat(point.getLon()) < parseFloat(lonLow)){
                lonLow = point.getLon();
            }
            return point;
        });

        return [{lat: parseFloat(latHigh), lng: parseFloat(lonLow)}, {lat: parseFloat(latLow), lng: parseFloat(lonHigh)}];
    }

    getTrackpoints(): TrackpointCollection {
        return this.trackerStore.getTrackpoints();
    }

    getTrackpointLoadingStatus() {
        return this.trackerStore.getLoadingTrackpointsStatus();
    }

    getShowDetailStatus(): boolean {
        return this.trackerStore.getShowDetail();
    }

    toggleDetail() {
        return this.trackerStore.setShowDetail(!this.trackerStore.getShowDetail());
    }

    setPanelToShow(panel: string): void {
        this.trackerStore.setPanelToShow(panel);
    }

    getPanelToShow(): string {
        return this.trackerStore.getPanelToShow();
    }

    fetchKeywords(date: Date): void {
        this.keywordStore.fetchKeywords(date).catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Punkte konnten nicht geladen werden!");
        });
    }

    setKeywords(keywords: string): void {
        this.keywordStore.setKeywords(keywords);
    }

    getKeywords(): string {
        return this.keywordStore.getKeywords();
    }

    updateKeywords(): void {
        this.keywordStore.updateKeywords(this.trackerStore.getSelectedDate())
            .then((response) => {
                NotificationUiService.getInstance().showSuccessNotification("Gespeichert!", "Der Eintrag wurde aktualisiert!");
            })
            .catch((e) => {
                NotificationUiService.getInstance().showErrorNotification("Fehler!", "Keywords konnten nicht geladen werden!");
            });
    }

    searchKeywords(): void {
        this.keywordStore.searchKeywords()
            .catch((e) => {
                NotificationUiService.getInstance().showErrorNotification("Fehler!", "Keywords konnten nicht geladen werden!");
            });
    }

    getKeywordsSearchResult(): Array<Keywords> {
        return this.keywordStore.getKeywordsSearchResult();
    }

    updateKeywordSearchTerm(term: string): void {
        this.keywordStore.updateKeywordSearchTerm(term)
    }

    getKeywordSearchTerm(): string {
        return this.keywordStore.getKeywordSearchTerm();
    }

    editTrackPoint(point: Trackpoint): void {
        this.trackerStore.editTrackpoint(point);
    }

    getSelectedPoint(): Trackpoint {
        return this.trackerStore.getSelectedTrackpoint();
    }

    activateEditMode(layer: string): void {
        return this.trackerStore.setEditModeStatus(layer, true);
    }

    deactivateEditMode(layer: string): void {
        //error bei: onRemoveLayers. erst wird der layer entfernt, dann versucht der marker auf null zu setzen -> geht nicht
        // this.trackerStore.editTrackpoint(null);
        return this.trackerStore.setEditModeStatus(layer, false);
    }

    getEditModeStatus(layer: string): boolean {
        return this.trackerStore.getEditModeStatus(layer);
    }

    resetTrackpointToEdit(): void {
        this.trackerStore.editTrackpoint(null);
    }

    moveMarker(id: number, lat: string, lon: string): void {
        this.trackerStore.updateMarker(id, lat, lon)
            .then((result) => {
                this.trackerStore.fetchTrackpoints(this.trackerStore.getSelectedDate())
            })
            // .then((dings) => {
            //     const updatedTrackpoint = this.trackerStore.getTrackpoints().getGpxPoints().filter((point) => {
            //         if(this.trackerStore.getSelectedTrackpoint().getId() == point.getId()){
            //             return point;
            //         }
            //     });
            //     this.editTrackPoint(Trackpoint.fromUpdated(updatedTrackpoint[0]));
            //     console.log(updatedTrackpoint)
            // })
            .catch((e) => {
                NotificationUiService.getInstance().showErrorNotification("Fehler!", "Punkt konnte nicht aktualisiert werden!");
            })
    }

    fetchAllKeywordDates(): void {
        this.keywordStore.fetchAllKeywordDates();
    }

    getAllKeywordDates() {
        return this.keywordStore.getAllKeywordDates() ? this.keywordStore.getAllKeywordDates() : [];
    }

    fetchAllDatesWithBikeTrackpoint(): void {
        this.trackerStore.fetchAllDatesWithBikeTrackpoint();
    }

    getAllDatesWithBikeTrackpoints() {
        return this.trackerStore.getAllDatesWithBikeTrackpoints() ? this.trackerStore.getAllDatesWithBikeTrackpoints() : [];
    }

}

export default TrackerUiService;