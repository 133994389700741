import Trackpoint from "./Trackpoint";
import TrackpointCollectionDto from "../../api/tracker/TrackpointCollectionDto";

class TrackpointCollection {
    bike: Array<Trackpoint>;
    gpx: Array<Trackpoint>;

    constructor(bike: Array<Trackpoint>, gpx: Array<Trackpoint>) {
        this.bike = bike;
        this.gpx = gpx
    }

    getBikePoints(): Array<Trackpoint> {
        return this.bike;
    }

    getGpxPoints(): Array<Trackpoint> {
        return this.gpx;
    }

    static createFrom(dto: TrackpointCollectionDto): TrackpointCollection {
        let bikePoints = [];
        let gpxPoints = [];

        dto.bike.map(point => {
            return bikePoints.push(Trackpoint.createFrom(point));
        });
        dto.gpx.map(point => {
            return gpxPoints.push(Trackpoint.createFrom(point))
        });

        return new TrackpointCollection(bikePoints, gpxPoints)
    }
}

export default TrackpointCollection;