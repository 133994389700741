import React from "react";
import {inject, observer} from "mobx-react";
import moment from 'moment';

const TrackpointsTable = inject("trackerUiService")(observer(({trackerUiService, trackpoints, layer}) => {

    const selectedTrackpointId = trackerUiService.getSelectedPoint() ? trackerUiService.getSelectedPoint().getId() : '';

    const renderEditRow = () => {
        return (
            <tr>
                <td colSpan={3}>
                    <div style={{width: '100%'}}>
                        edit:
                    </div>
                </td>
            </tr>
        )
    };

    const renderTrackpointRow = (point, selectedTrackpointId, layer) => {
        return (
            <React.Fragment key={point.getId()}>
                <tr key={point.getId()}
                    className={selectedTrackpointId === point.getId() ? 'currentEditing' : ''}
                    onClick={() => {
                        if (trackerUiService.getEditModeStatus(layer)) {
                            trackerUiService.editTrackPoint(point)
                        }
                    }}>
                    <td>
                        {moment(point.getDatetime()).format('HH:mm:ss')}<br/>
                        {point.getSource()}
                    </td>
                    <td>
                        {point.getLat()}<br/>
                        {point.getLon()}
                    </td>
                    <td>
                        {point.getRoute()} {point.streetNumber}<br/>
                        {point.getPostalCode()} {point.getLocality()}
                    </td>
                </tr>

                {selectedTrackpointId === point.getId() ? renderEditRow() : null}
            </React.Fragment>
        );
    };

    return (
        <table className="trackpoint-list">
            <thead className="thead-dark">
                <tr>
                    <th scope="col">Zeit / Source</th>
                    <th scope="col">Lat / Lon</th>
                    <th scope="col">Ort</th>
                </tr>
            </thead>
            <tbody>
            {trackpoints.map((point) => {
                return renderTrackpointRow(point, selectedTrackpointId, layer);
            })}
            </tbody>
        </table>
    )
}));

export default TrackpointsTable;