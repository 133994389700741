import axios from "axios";
import UserSessionStore from "../../store/security/UserSessionStore";
import NotificationUiService from "../../services/notification/NotificationUiService";

export default class SessionTimoutConfiguration {
    private userSessionStore: UserSessionStore;
    private readonly baseUrl: string;

    constructor(userSessionStore: UserSessionStore, baseUrl: string) {
        this.userSessionStore = userSessionStore;
        this.baseUrl = baseUrl;
    }

    public configureSessionTimeout(): void {
        axios.interceptors.response.use(
            response => response,
            error => this.handleError(error)
        );

        // axios.interceptors.response.use(
        //     response => {
        //         return response;
        //     },
        //     error => {
        //         const originalRequest = error.config;
        //
        //         let refreshToken = localStorage.getItem("refreshToken");
        //         let deviceId = localStorage.getItem("id");
        //
        //
        //         if (
        //             refreshToken &&
        //             error.response.status === 401 &&
        //             !originalRequest._retry
        //         ) {
        //             originalRequest._retry = true;
        //             return axios
        //                 .post(`${this.baseUrl}/validate`, {refreshToken: refreshToken, fingerprint: deviceId})
        //                 .then((res) => {
        //                     if (res.status === 200) {
        //                         localStorage.setItem("authToken", res.data.token);
        //                         localStorage.setItem("refreshToken", res.data.refreshToken);
        //
        //
        //                         console.log("Access token refreshed!");
        //                         return axios(originalRequest);
        //                     }
        //                 })
        //                 .catch(error => {
        //                     this.handleError(error)
        //                 });
        //         }
        //         return Promise.reject(error);
        //     }
        // );


    }

    private handleError(error: any): Promise<any> {
        if (error.response.data.path === "/api/v1/auth") {
            return error;
        }

        if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
            this.userSessionStore.clear();
            NotificationUiService.getInstance().showErrorNotification("Sitzung abgelaufen!", "Sie sind nicht authentifiziert und wurden zur Login-Seite weitergeleitet!");
        }
        return Promise.reject(error);
    }
}