import React, {useEffect} from 'react';
import {inject, observer} from "mobx-react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, {CalendarContainer, registerLocale} from "react-datepicker";
import de from "date-fns/locale/de";

registerLocale("de", de);

const CalendarSelect = inject("trackerUiService")(observer(({trackerUiService}) => {
    useEffect(() => {
            const fetchData = async (): Promise<any> => {
                trackerUiService.fetchAllKeywordDates();
                trackerUiService.fetchAllDatesWithBikeTrackpoint();
            };
            fetchData()
                .then(r => r)
                .catch(error => {
                    throw error;
                });

        },
        [
            trackerUiService
        ]);

    const MyContainer = ({className, children}) => {
        return (
            <div className="datePickerContainer">
                <CalendarContainer className={className}>
                    <div style={{position: "relative"}}>{children}</div>
                </CalendarContainer>
            </div>
        )
    };

    return (
        <React.Fragment>
            <button className="btn btn-sm btn-left"
                    onClick={() => trackerUiService.oneDayBackward()}>
                <i className="fas fa-chevron-left"/>
            </button>
            <DatePicker
                selected={trackerUiService.getSelectedDate()}
                onChange={date => trackerUiService.setSelectedDate(date)}
                onKeyDown={e => e.preventDefault()}
                onFocus={e => e.preventDefault()}
                todayButton="Heute"
                showYearDropdown
                showMonthDropdown
                highlightDates={[
                    {
                        "react-datepicker__day--highlighted-bike": trackerUiService.getAllDatesWithBikeTrackpoints()
                    },
                    {
                        "react-datepicker__day--highlighted-note": trackerUiService.getAllKeywordDates()
                    }
                ]}
                locale="de"
                dateFormat="EEE dd.MM.yyyy"
                calendarContainer={MyContainer}
            />
            <button className="btn btn-sm btn-right"
                    onClick={() => trackerUiService.oneDayForward()}>
                <i className="fas fa-chevron-right"/>
            </button>


        </React.Fragment>
    );
}));

export default CalendarSelect;