import axios, {AxiosPromise} from "axios";
import KeywordsDto from "./KeywordsDto";
import KeywordCommand from "./KeywordsCommand";

class KeywordsClient {
    private readonly _baseUrl: string;

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl + "/note";
    }

    getKeywordsForSelectedDate(date: string): AxiosPromise {
        const url = `${this._baseUrl}/date/${date}`;
        return axios.get<KeywordsDto>(url);
    }

    getAllKeywordDates(): AxiosPromise {
        const url = `${this._baseUrl}/dates`;
        return axios.get<Array<string>>(url);
    }

    updateKeywords(keyword: KeywordCommand): AxiosPromise {
        const url = `${this._baseUrl}/save`;
        return axios.post(url, keyword);
    }

    searchKeywords(term: string): AxiosPromise {
        const url = `${this._baseUrl}/search/${term}`;
        return axios.get<Array<KeywordsDto>>(url);
    }
}

export default KeywordsClient;