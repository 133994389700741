import React, {useEffect} from "react";
import {FeatureGroup, LayersControl, Map, Marker, Polyline, TileLayer} from "react-leaflet";
import {divIcon} from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {inject, observer} from "mobx-react";
import {renderToStaticMarkup} from "react-dom/server";
import EditOverlay from "./subcomponents/EditOverlay";

const {BaseLayer, Overlay} = LayersControl;

const basemap = {url: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}', subdomains: ['mt0', 'mt1', 'mt2', 'mt3']}
const streetmap = {url: 'http://{s}.google.com/vt/lyrs=r,h&x={x}&y={y}&z={z}', subdomains: ['mt0', 'mt1', 'mt2', 'mt3']}
const heighmap = {url: 'http://{s}.google.com/vt/lyrs=p,h&x={x}&y={y}&z={z}', subdomains: ['mt0', 'mt1', 'mt2', 'mt3']}
const topomap = {
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
}
const osmap = {
    url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
};

const renderIcon = (edit = '') => {
    return divIcon({
        html: renderToStaticMarkup(<i className={"fas fa-map-marker-alt fa-3x gpx-marker marker " + edit}/>)
    })
};

const renderBikeIcon = (edit = '') => {
    return divIcon({
        html: renderToStaticMarkup(<i className={"fas fa-map-pin fa-3x bike-marker marker " + edit}/>)
    })
};

const createPolyLine = (trackpoints) => {
    let polyline = [];

    trackpoints.map((item) => {
        let point = [item.lat, item.lon];
        return polyline.push(point)
    });
    return polyline;
};

const LeafletMap = inject("trackerUiService")(observer(({trackerUiService}) => {

    useEffect(() => {
            const fetchData = async (): Promise<any> => {
                trackerUiService.fetchTrackpointsForDate(trackerUiService.getSelectedDate());
            };
            fetchData()
                .then(r => r)
                .catch(error => {
                    throw error;
                });

        },
        [
            trackerUiService
        ]);

    const selectedTrackpointId = trackerUiService.getSelectedPoint() ? trackerUiService.getSelectedPoint().getId() : null;
    const bounds = trackerUiService.calculateBounds();


    return (
        <React.Fragment>
            <Map className={trackerUiService.getSelectedPoint() ? 'leaflet-map hide-controls' : 'leaflet-map'}
                bounds={bounds}
                 onClick={(e) => {
                     var coord = e.latlng;
                     var lat = coord.lat;
                     var lng = coord.lng;
                     console.log("You clicked the map at latitude: " + lat + " and longitude: " + lng);
                 }}
                 onOverlayAdd={(e) => {
                     trackerUiService.activateEditMode(e.name)
                 }}
                 onOverlayRemove={(e) => {
                     trackerUiService.deactivateEditMode(e.name)
                 }}>

                <EditOverlay/>


                <LayersControl position="topright" className="hide">
                    <BaseLayer checked name="Base">
                        <TileLayer url={basemap.url} subdomains={basemap.subdomains}/>
                    </BaseLayer>
                    <BaseLayer name="Street">
                        <TileLayer url={streetmap.url} subdomains={streetmap.subdomains}/>
                    </BaseLayer>
                    <BaseLayer name="Height">
                        <TileLayer url={heighmap.url} subdomains={heighmap.subdomains}/>
                    </BaseLayer>
                    <BaseLayer name="Osm">
                        <TileLayer url={osmap.url} attribution={osmap.attribution}/>
                    </BaseLayer>
                    <BaseLayer name="Topo">
                        <TileLayer url={topomap.url} attribution={topomap.attribution}/>
                    </BaseLayer>

                    <Overlay checked={true} name="GPX Strecke">
                        <Polyline color="blue"
                                  positions={createPolyLine(trackerUiService.getTrackpoints().getGpxPoints())}/>
                    </Overlay>
                    <Overlay name="Gpx Marker">
                        <FeatureGroup color="blue">
                            {trackerUiService.getTrackpoints().getGpxPoints().map((point) => {
                                return (
                                    <Marker key={point.getId()}
                                            position={[point.getLat(), point.getLon()]}
                                            onClick={() => trackerUiService.editTrackPoint(point)}
                                            draggable={selectedTrackpointId === point.getId()}
                                            icon={selectedTrackpointId === point.getId() ? renderIcon('edit') : renderIcon()}
                                            onDragEnd={(e) => trackerUiService.moveMarker(point.getId(), e.target._latlng.lat, e.target._latlng.lng)}/>
                                )
                            })}
                        </FeatureGroup>
                    </Overlay>

                    <Overlay name="Bike Strecke">
                        <Polyline color="red"
                                  positions={createPolyLine(trackerUiService.getTrackpoints().getBikePoints())}/>
                    </Overlay>
                    <Overlay name="Bike Marker">
                        <FeatureGroup color="red">
                            {trackerUiService.getTrackpoints().getBikePoints().map((point) => {
                                return (
                                    <Marker key={point.getId()}
                                            position={[point.getLat(), point.getLon()]}
                                            onClick={() => trackerUiService.editTrackPoint(point)}
                                            icon={selectedTrackpointId === point.getId() ? renderBikeIcon('edit') : renderBikeIcon()}/>
                                )
                            })}
                        </FeatureGroup>
                    </Overlay>


                </LayersControl>
            </Map>
        </React.Fragment>
    )
}));

export default LeafletMap;