import {action, observable, toJS} from "mobx";
import {AxiosPromise} from "axios";
import Keywords from "../../model/keyword/Keywords";
import KeywordCommand from "../../api/keywords/KeywordsCommand";
import KeywordsClient from "../../api/keywords/KeywordsClient";
import moment from "moment";


class KeywordsStore {

    @observable
    private keywords: string;

    @observable
    private keywordSearchTerm: string;

    @observable
    private keywordsSearchResult: Array<Keywords>;

    @observable
    private keywordDates: Array<Date>;


    private client: KeywordsClient;

    constructor(client: KeywordsClient) {
        this.client = client;
        this.init();
    }

    @action
    private init(): void {
        this.keywordSearchTerm = '';
        this.keywordsSearchResult = [];
    }

    updateKeywords(date: Date): Promise<any> {
        return this.client.updateKeywords(
            new KeywordCommand(this.keywords, moment(date).format('YYYY-MM-DD'))
        );
    }

    fetchKeywords(date: Date): AxiosPromise {
        return this.client.getKeywordsForSelectedDate(moment(date).format('YYYY-MM-DD')).then(response => {
            const keywordDto = response.data.data ? response.data.data : '';
            this.setKeywords(Keywords.createFrom(keywordDto).getKeywords());
            return response;
        });
    }

    @action
    setKeywords(keywords: string): void {
        this.keywords = keywords;
    }

    getKeywords(): string {
        return this.keywords;
    }

    searchKeywords(): AxiosPromise {
        return this.client.searchKeywords(this.keywordSearchTerm).then(response => {
            const result = response.data.data;
            let searchResult = [];
            result.map(dto => {
                return searchResult.push(Keywords.createFrom(dto))
            });
            this.setKeywordsSearchResult(searchResult);
            return response;
        });
    }

    @action
    setKeywordsSearchResult(searchResult: Array<Keywords>): void {
        this.keywordsSearchResult = searchResult;
    }

    getKeywordsSearchResult(): Array<Keywords> {
        return this.keywordsSearchResult;
    }

    @action
    updateKeywordSearchTerm(value: string): void {
        this.keywordSearchTerm = value;
    }

    getKeywordSearchTerm(): string {
        return this.keywordSearchTerm
    }

    fetchAllKeywordDates(): void {
        this.client.getAllKeywordDates().then(response => {
            let keywordDates = []
            const result = response.data.data;
            result.map(keywordDate => {
                return keywordDates.push(new Date(keywordDate))
            });
            this.setAllKeywordDates(keywordDates);
        })
    }

    @action
    private setAllKeywordDates(dates): void {
        this.keywordDates = dates;
    }

    getAllKeywordDates() {
        return toJS(this.keywordDates);
    }




}

export default KeywordsStore;