import KeywordsDto from "../../api/keywords/KeywordsDto";

class Keywords {
    keywords: string;
    date: string;

    constructor(keywords: string, date: string) {
        this.keywords = keywords;
        this.date = date;
    }

    getDate():string{
      return this.date;
    }

    getKeywords(): string{
        return this.keywords;
    }

    setKeywords(keywords: string){
        this.keywords = keywords;
    }

    static createFrom(dto: KeywordsDto): Keywords {
        return new Keywords(
            dto.keywords,
            dto.date)
    }
}

export default Keywords;