import React from "react";
import {inject, observer} from "mobx-react";
import {Col, Row} from "react-bootstrap";
import Keyword from "./subcomponents/Keyword";
import Search from "./subcomponents/Search";
import Trackpoints from "./subcomponents/Trackpoints";

const Detail = inject("trackerUiService")(observer(({trackerUiService}) => {

    return (
        <React.Fragment>
            <Row className="mobile-navigation">
                <Col onClick={() => {
                    trackerUiService.setPanelToShow('keyword')
                }} className="btn btn-secondary">Info</Col>
                <Col onClick={() => {
                    trackerUiService.setPanelToShow('search')
                }} className="btn btn-secondary">Suche</Col>
                <Col onClick={() => {
                    trackerUiService.setPanelToShow('trackpoints')
                }} className="btn btn-secondary">Punkte</Col>
            </Row>
            <Row className="panels">
                <div id="keywords" className={trackerUiService.getPanelToShow() === 'keyword' ? 'show' : 'hide'}>
                    <Keyword/>
                </div>
                <div id="search" className={trackerUiService.getPanelToShow() === 'search' ? 'show' : 'hide'}>
                    <Search/>
                </div>
                <div id="trackpoints" className={trackerUiService.getPanelToShow() === 'trackpoints' ? 'show' : 'hide'}>
                    <Trackpoints/>
                </div>
            </Row>

        </React.Fragment>
    )
}));

export default Detail;