import axios, {AxiosPromise} from "axios";
import TrackpointCollectionDto from "./TrackpointCollectionDto";
import MovedMarkerCommand from "./MovedMarkerCommand";

class TrackerClient {
    private readonly _baseUrl: string;

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl + "/tracker";
    }

    getTrackpointsForSelectedDate(date: string): AxiosPromise {
        const url = `${this._baseUrl}/points/${date}`;
        return axios.get<TrackpointCollectionDto>(url);
    }

    updateTrackpointPosition(command: MovedMarkerCommand): AxiosPromise {
        const url = `${this._baseUrl}/update/position`;
        return axios.post(url, command);
    }

    getAllDatesWithBikeTrackpoints(): AxiosPromise {
        const url = `${this._baseUrl}/dates/bike`;
        return axios.get<Array<string>>(url);
    }
}

export default TrackerClient;