import {action, observable, toJS} from "mobx";
import TrackerClient from "../../api/tracker/TrackerClient";
import TrackpointCollection from "../../model/trackpoint/TrackpointCollection";
import TrackpointCollectionDto from "../../api/tracker/TrackpointCollectionDto";
import {AxiosPromise} from "axios";
import Trackpoint from "../../model/trackpoint/Trackpoint";
import MovedMarkerCommand from "../../api/tracker/MovedMarkerCommand";
import moment from 'moment';

class TrackerStore {
    @observable
    private selectedDate: Date;

    @observable
    private trackpoints: TrackpointCollection;

    @observable
    private isLoadingTrackpoints: boolean;

    @observable
    private showDetail: boolean;

    @observable
    private panelToShow: string; //keword, search, trackpoints

    @observable
    private trackpointToEdit: Trackpoint;

    @observable
    private editModeTracker: boolean;

    @observable
    private editModeBike: boolean;

    @observable
    private bikepointDates: Array<Date>;

    private client: TrackerClient;

    constructor(client: TrackerClient) {
        this.client = client;
        this.init();
    }

    @action
    private init(): void {
        this.selectedDate = new Date(moment().format('YYYY-MM-DD'));
        this.trackpoints = new TrackpointCollection([], []);
        this.isLoadingTrackpoints = false;
        this.showDetail = false;
        this.panelToShow = 'keyword';
        this.editModeTracker = false;
        this.editModeBike = false;
        this.trackpointToEdit = null;
        this.bikepointDates = [];
    }

    getSelectedDate(): Date {
        return this.selectedDate;
    }

    @action
    private changeDate(date: Date): void {
        this.selectedDate = date;
    }

    setSelectedDate(date: Date): void {
        this.changeDate(date);
        this.fetchTrackpoints(date)
    }

    @action
    private setTrackpoints(dto: TrackpointCollectionDto): void {
        this.trackpoints = TrackpointCollection.createFrom(dto);
    }

    @action
    private setLoadingTrackpointsStatus(status: boolean): void {
        this.isLoadingTrackpoints = status;
    }

    getLoadingTrackpointsStatus(): boolean {
        // return false;
        return this.isLoadingTrackpoints;
    }

    fetchTrackpoints(date: Date): AxiosPromise {
        this.setLoadingTrackpointsStatus(true);
        return this.client.getTrackpointsForSelectedDate(moment(date).format('YYYY-MM-DD')).then(response => {
            this.setTrackpoints(response.data.data);
            this.setLoadingTrackpointsStatus(false);
            return response;
        });
    }

    getTrackpoints(): TrackpointCollection {
        return this.trackpoints;
    }

    @action
    private setShowDetailStatus(status: boolean): void {
        this.showDetail = status;
    }

    setShowDetail(status: boolean): void {
        this.setShowDetailStatus(status);
    }

    getShowDetail(): boolean {
        return this.showDetail;
    }

    @action
    private updatePanelToShow(panel: string): void {
        this.panelToShow = panel;
    }

    setPanelToShow(panel: string): void {
        this.updatePanelToShow(panel);
    }

    getPanelToShow(): string {
        return this.panelToShow;
    }

    @action
    editTrackpoint(point: Trackpoint): void {
        this.trackpointToEdit = point;
    }

    getSelectedTrackpoint(): Trackpoint {
        return this.trackpointToEdit;
    }

    @action
    setEditModeStatus(layer: string, status: boolean): void {
        if(layer === 'Gpx Marker'){
            this.editModeTracker = status;
        }
        if(layer === 'Bike Marker'){
            this.editModeBike = status;
        }

    }

    getEditModeStatus(layer: string): boolean {
        if(layer === 'Gpx Marker'){
            return this.editModeTracker;
        }
        if(layer === 'Bike Marker'){
            return this.editModeBike;
        }
    }

    updateMarker(id: number, lat: string, lon: string): Promise<any> {
        return this.client.updateTrackpointPosition(new MovedMarkerCommand(id, lat, lon))
    }

    fetchAllDatesWithBikeTrackpoint(): void {
        this.client.getAllDatesWithBikeTrackpoints().then(response => {
            let bikepointDates = []
            const result = response.data.data;
            result.map(bikepointDate => {
                return bikepointDates.push(new Date(bikepointDate))
            });
            this.setAllBikepointDates(bikepointDates);
        })
    }

    @action
    private setAllBikepointDates(dates): void {
        dates.push(new Date('2020-07-01'));
        this.bikepointDates = dates;
    }

    getAllDatesWithBikeTrackpoints() {
        return toJS(this.bikepointDates);
    }

}

export default TrackerStore;