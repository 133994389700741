import React, {useState} from "react";
import LeafletMap from "../map/LeafletMap";
import CalendarSelect from "../calendar/CalendarSelect";
import {inject, observer} from "mobx-react";
import {Button} from "react-bootstrap";
import Detail from "../detail/Detail";
import VersionInfo from "../versioninfo/VersionInfo";
import Logout from "../security/Logout";

const Index = inject("trackerUiService", "securityUiService")(observer(({trackerUiService, securityUiService}) => {

    const addDynamicClasses = () => {
        let classNames = ['trackermap'];
        if (trackerUiService.getShowDetailStatus()) {
            classNames.push('show-detail')
        }
        if (trackerUiService.getSelectedPoint()) {
            classNames.push('edit-active')
        }
        return classNames.join(' ');
    };

    const [loggedOut, setLoggedOut] = useState(false)

    const logout = () => {
        localStorage.clear();
        setLoggedOut(true);
    };

    if (loggedOut) {
        return <Logout/>
    }

    return (
        <React.Fragment>
            <div className="calendar">
                <CalendarSelect/>
            </div>
            <div className={trackerUiService.getShowDetailStatus() ? "detail show-detail" : "detail"}>
                <Detail/>
            </div>
            <div className={addDynamicClasses()}>
                <LeafletMap/>
            </div>

            <div className="loader-wrapper">
                {trackerUiService.getTrackpointLoadingStatus() ? <div className="loader"></div> : null}
            </div>

            <div className="detail-button">
                <Button variant="secondary" className="btn-round" onClick={() => trackerUiService.toggleDetail()}>
                    <i className="fas fa-pen"></i>
                </Button>
            </div>

            <div className="logout">
                <Button className="logout-btn btn btn-danger" onClick={() => logout()}>
                    <i className="fas fa-sign-out-alt"></i>
                </Button>

            </div>

            <div className="version-info">
                <VersionInfo/>
            </div>
        </React.Fragment>
    )
}));

export default Index;