import React from "react";
import {inject, observer} from "mobx-react";
import {HashLink as Link} from 'react-router-hash-link';
import TrackpointsTable from "./table/TrackpointsTable";
import {Col, Row} from "react-bootstrap";


const Trackpoints = inject("trackerUiService")(observer(({trackerUiService}) => {

    const renderAnchorCol = (target, icon, direction) => {
        return (
            <Col>
                <Link to={'/#' + target}>
                    <i className={'fas fa-chevron-' + direction}/>{'\u00A0'}
                    <i className={icon}/>{'\u00A0'}
                    <i className={'fas fa-chevron-' + direction}/>
                </Link>
            </Col>
        )
    };

    return (
        <div className="trackpoints">

            <Row id="gpx" className="trackpoint-anchor text-center">
                {renderAnchorCol('bike', 'fas fa-bicycle', 'down')}
            </Row>
            <TrackpointsTable trackpoints={trackerUiService.getTrackpoints().getGpxPoints()} layer='Gpx Marker'/>

            <Row id="bike" className="trackpoint-anchor text-center">
                {renderAnchorCol('gpx', 'fas fa-road', 'up')}
            </Row>
            <TrackpointsTable trackpoints={trackerUiService.getTrackpoints().getBikePoints()} layer='Bike Marker'/>

            <Row className="trackpoint-anchor text-center">
                {renderAnchorCol('gpx', 'fas fa-road', 'up')}
                {renderAnchorCol('bike', 'fas fa-bicycle', 'up')}
            </Row>
        </div>
    )
}));

export default Trackpoints;