import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Provider} from "mobx-react";
import Routes from "../api/Routes";
import Index from "./index/Index";
import TrackerClient from "../api/tracker/TrackerClient";
import TrackerStore from "../store/tracker/TrackerStore";
import TrackerUiService from "../services/tracker/TrackerUiService";
import Config from "../Config";
import KeywordsClient from "../api/keywords/KeywordsClient";
import KeywordsStore from "../store/keywords/KeywordsStore";
import VersionInfoStore from "../store/versioninfo/VersionInfoStore";
import VersionInfoClient from "../api/versioninfo/VersionInfoClient";
import VersionInfoUiService from "../services/versioninfo/VersionInfoUiService";


const trackerStore = new TrackerStore(new TrackerClient(Config.BACKEND_API));
const keywordsStore = new KeywordsStore(new KeywordsClient(Config.BACKEND_API));
const trackerUiService = new TrackerUiService(trackerStore, keywordsStore);

const versionInfoStore = new VersionInfoStore(new VersionInfoClient(Config.INFO_API));
const versionInfoUiService = new VersionInfoUiService(versionInfoStore);

const stores = {
    trackerUiService,
    versionInfoUiService
};

const App = () => (
    <React.Fragment>
        <Provider {...stores}>
            <BrowserRouter>
                <React.Fragment>
                    <main>
                        <div className="content-container container-fluid bg-dark text-light">
                            <Switch>
                                <Route path={Routes.INDEX} exact component={Index}/>
                            </Switch>
                        </div>
                    </main>
                </React.Fragment>
            </BrowserRouter>
        </Provider>
    </React.Fragment>
);

export default App;
