import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";
import {Button, Col, FormControl, InputGroup, Row} from "react-bootstrap";

const Keyword = inject("trackerUiService")(observer(({trackerUiService}) => {
    useEffect(() => {
            const fetchData = async (): Promise<any> => {
                trackerUiService.fetchKeywords(trackerUiService.getSelectedDate());
            };
            fetchData()
                .then(r => r)
                .catch(error => {
                    throw error;
                });

        },
        [
            trackerUiService
        ]);
    return (
        <React.Fragment>

            <InputGroup>
                <FormControl
                    as="textarea"
                    aria-label="With textarea"
                    className="keyword-textarea"
                    value={trackerUiService.getKeywords() ? trackerUiService.getKeywords() : ''}
                    onChange={(e: any) => trackerUiService.setKeywords(e.target.value)}
                />
            </InputGroup>
            <Row>
                <Col className="save">
                    <Button variant="primary"
                            className="btn-block"
                            onClick={() => trackerUiService.updateKeywords()}>
                        <i className="fas fa-check"></i>
                    </Button>
                </Col>
                <Col className="cancel">
                    <Button variant="warning"
                            className="btn-block"
                            onClick={() => trackerUiService.fetchKeywords(trackerUiService.getSelectedDate())}>
                        <i className="fas fa-times"></i>
                    </Button>
                </Col>
            </Row>

        </React.Fragment>
    )
}));

export default Keyword;