import React from "react";
import {inject, observer} from "mobx-react";
import {Alert, AlertContainer} from "react-bs-notifier";
import "react-toastify/dist/ReactToastify.css";

const NotificationComponent = inject("notificationUiService")(observer(({notificationUiService}) => {
    const showAlerts = () => notificationUiService.notifications.map(a => (
        <Alert id={a.id}
               key={a.key}
               type={a.type}
               headline={a.headline}
               onDismiss={() => notificationUiService.onAlertDismissed(a)}
               timeout={a.timeout}>
            {a.message}
        </Alert>));

    return (
        <div className="notification">
            <AlertContainer position={"bottom-right"}>
                {notificationUiService.notifications.length > 0 ? showAlerts() : null}
            </AlertContainer>
        </div>
    );
}));

export default NotificationComponent;